import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-banner-other',
  templateUrl: './banner-other.component.html',
  styleUrls: ['./banner-other.component.css']
})
export class BannerOtherComponent implements OnInit {

  @Input() texto : any
  title : any

  constructor() { }

  ngOnInit() {
    this.title = this.texto
  }

}
