import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppComponent } from './app.component';
import { HeaderComponent } from './views/header/header.component';
import { FooterComponent } from './views/footer/footer.component';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './views/home/home.component';
import { BannerHomeComponent } from './views/home/banner-home/banner-home.component';
import { CareerComponent } from './views/career/career.component';
import { BannerOtherComponent } from './views/banner-other/banner-other.component';
import { AboutUsComponent } from './views/about-us/about-us.component';
import { OfferComponent } from './views/offer/offer.component';
import { IndustryComponent } from './views/industry/industry.component';
import { ContactUsComponent } from './views/contact-us/contact-us.component';


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    BannerHomeComponent,
    CareerComponent,
    BannerOtherComponent,
    AboutUsComponent,
    OfferComponent,
    IndustryComponent,
    ContactUsComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
