<app-header></app-header>
<section class="banner-other">
  <div class="container">
    <div class="row">
      <div class="col s12 white-text">
        <h5 class="title-about">{{ "ABOUT.title-section-1" | translate }}</h5>
        <!-- <h6 class="breadcrumbs"><a routerLink="/" class="grey-text">Início</a> / <span>{{title}}</span> </h6> -->
      </div>
    </div>
  </div>
</section>


<main>
  <section class="texto-justificado espaco-p-top-1 espaco-p-baixo-1">
    <div class="container">
      <div class="row">
        <div class="col s12 m12 l2">
          <img src="assets/imgs/logo-espaes-3.jfif" class="mt-4 responsive-img" alt="">
        </div>

        <div class="col s12 m12 l10">

          <p>{{ "ABOUT.paragraph-1" | translate }}</p>
          <p>{{ "ABOUT.paragraph-2" | translate }}</p>
          <p>{{ "ABOUT.paragraph-3" | translate }}</p>
          <p>{{ "ABOUT.paragraph-4" | translate }}</p>
        </div>
      </div>
    </div>
  </section>

  <section class="texto-justificado banner-about py-5 white-text">
    <div class="container">
      <div class="row">
        <div class="col s12 m12 l2">

        </div>
        <div class="col s12 m12 l10">
          <div class="row">

            <div class="col s12 m12 l12 cartoes-sobre">
              <div class="">
                <div class="">
                <h5 class="negrito">{{ "ABOUT.mision-title" | translate }} </h5>
                <p class="linha-top-30 center texto-justificado">{{ "ABOUT.mision-text" | translate }} </p>
                </div>
              </div>
            </div>

            <div class="col s12 cartoes-sobre">
              <div class="">
                <div class="">
                  <h5 class="negrito">{{ "ABOUT.vision-title" | translate }}</h5>
                  <p class="center linha-top-30 texto-justificado">{{ "ABOUT.vision-text" | translate }}</p>
                </div>

              </div>
            </div>

            <div class="col s12 cartoes-sobre">
              <div class="">
                <h5 class="negrito">{{ "ABOUT.values-title" | translate }}</h5>
              </div>

              <ul class="">
                <li class="">{{ "ABOUT.values-text-1" | translate }}<li>
                <li class="">{{ "ABOUT.values-text-2" | translate }}<li>
                <li class="">{{ "ABOUT.values-text-3" | translate }}<li>
                <li class="">{{ "ABOUT.values-text-4" | translate }}<li>
              </ul>
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
</main>

<app-footer></app-footer>
