<app-header></app-header>
<section class="banner-other">
  <div class="container">
    <div class="row">
      <div class="col s12 white-text">
        <h5 class="title-about">{{ "CAREER.title-section-1" | translate }}</h5>
      </div>
    </div>
  </div>
</section>

<main>
  <section>
      <div class="container">
        <div class="row espaco-p-top-1">
          <div class="col s12 m12 l2">
            <img src="assets/imgs/logo-espaes-3.jfif" class="mt-4 responsive-img" alt="">
          </div>

          <div class="col s12 m12 l10">
            <h5 class="top-title text-color-1">ESPAES</h5>
          <h3 class="title-page text-color-1">{{ "CAREER.title-section-2" | translate }} </h3>

          <p class="texto-justificado">
            {{ "CAREER.paragraph-1" | translate }}
          </p>

          <p class="texto-justificado">
            {{ "CAREER.paragraph-2" | translate  }}
          </p>

          <p class="texto-justificado">
            {{ "CAREER.paragraph-3"  | translate }}
          </p>

          <p class="texto-justificado">
            {{ "CAREER.paragraph-4" | translate  }} <span class="negrito text-color-1">aquisicaodetalento@espaes.com</span>
          </p>
          <p class="texto-justificado">
            {{ "CAREER.paragraph-5" | translate  }}
          </p>
          </div>
        </div>
      </div>
  </section>
</main>


<app-footer></app-footer>
