import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.css']
})
export class OfferComponent implements OnInit {
  title = "Oferta"

  constructor(private titleService:Title) {
    this.titleService.setTitle(`ESPAES - ${this.title}`);
  }

  ngOnInit(): void {
  }

}
