<app-header></app-header>
<!-- <app-banner-other [texto]="this.title" ></app-banner-other> -->
<section class="banner-industry espaco-p-top-1 espaco-p-baixo-1">
  <div class="container">
    <div class="row m-0">
      <div class="col s12 m12 l10 offset-l1">
        <div class="center-align">
          <h4 class="grey-text text-lighten-2 texto-uppercase title-up" style="letter-spacing: 5pt;">{{ "INDUSTRY.title-section-1" | translate }} </h4>

          <p class="grey-text text-lighten-2 linha-top-30">
            {{ "INDUSTRY.paragraph-1" | translate }}
          </p>

          <p class="grey-text text-lighten-2 linha-top-30">
            {{ "INDUSTRY.paragraph-2" | translate }}
          </p>

        </div>
      </div>
    </div>

    <div class="row">
      <div class="col s12 m12 l10 offset-l1">

        <div class="row">

          <div class="col s12 m12 l12 center">
          <h5 class="top-title white-text texto-2">{{ "INDUSTRY.paragraph-3" | translate }}</h5>
          </div>

          <div class="col s12 m12 l4">
            <div class="card-panel">
              {{ "INDUSTRY.list-item-1" | translate }}
            </div>
          </div>


          <div class="col s12 m12 l4">
            <div class="card-panel">
              {{ "INDUSTRY.list-item-2" | translate }}
            </div>
          </div>


          <div class="col s12 m12 l4">
            <div class="card-panel">
              {{ "INDUSTRY.list-item-3" | translate }}
            </div>
          </div>

          <div class="col s12 m12 l4">
            <div class="card-panel">
              {{ "INDUSTRY.list-item-4" | translate }}
            </div>
          </div>

          <div class="col s12 m12 l4">
            <div class="card-panel">
              {{ "INDUSTRY.list-item-5" | translate }}
            </div>
          </div>

          <div class="col s12 m12 l4">
            <div class="card-panel">
              {{ "INDUSTRY.list-item-6" | translate }}
            </div>
          </div>
          <div class="col s12 m12 l4">
            <div class="card-panel">
              {{ "INDUSTRY.list-item-7" | translate }}
            </div>
          </div>
          <div class="col s12 m12 l4">
            <div class="card-panel">
              {{ "INDUSTRY.list-item-8" | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-footer></app-footer>
