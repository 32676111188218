<section class="no-scroll">
  <app-header></app-header>
  <app-banner-home></app-banner-home>
  <footer class="page-footer footer">
    <div class="footer-copyright">
      <div class="container pb-3">
      © 2024 ESPAES
      <a class="grey-text text-lighten-4 right ms-4" href="https://www.instagram.com/espaes_group/" target="_blank"><span class="fab fa-instagram"></span></a>
      <a class="grey-text text-lighten-4 right ms-4" href="https://www.linkedin.com/company/espaes-group/mycompany/" target="_blank"><span class="fab fa-linkedin"></span></a>
      <a class="grey-text text-lighten-4 right ms-4" href="https://www.facebook.com/profile.php?id=100086102329090&mibextid=ZbWKwL" target="_blank"><span class="fab fa-facebook"></span></a>

      <span class="hide-on-med-and-down">
        <a class="grey-text text-lighten-4 right ms-4" routerLink="/fale-connosco" routerLinkActive="active">{{ "MENU.contact-us" | translate }}</a>
      <a class="grey-text text-lighten-4 right ms-4" routerLink="/carreira" routerLinkActive="active">{{ "MENU.career" | translate }}</a>
      <a class="grey-text text-lighten-4 right ms-4" routerLink="/industria" routerLinkActive="active">{{ "MENU.industry" | translate }}</a>
      <a class="grey-text text-lighten-4 right ms-4" routerLink="/oferta" routerLinkActive="active">{{ "MENU.offer" | translate }}</a>
      <a class="grey-text text-lighten-4 right ms-4" routerLink="/sobre-nos" routerLinkActive="active">{{ "MENU.about-us" | translate }}</a>
      <a class="grey-text text-lighten-4 right ms-4" routerLink="/inicio" routerLinkActive="active">{{ "MENU.index" | translate }}</a>
      </span>
      </div>
    </div>
  </footer>

</section>
