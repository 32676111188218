<section class="banner-index" style="position: relative;">

  <div class="overlay1"></div>
  <div class="overlay2"></div>
  <div class="overlay3"></div>
  <div class="overlay4"></div>
  <div class="overlay5"></div>

    <div class="container">
      <div class="row espaco-p-top-2">
        <div class="col s12 m12 l12 white-text"  style="position: relative;">
          <div class="center-align center-block">
            <img src="assets/imgs/logo-espaes.png" class="responsive-img" alt="">
            <h1 class="m-0 negrito">{{ "HOME.TITLE" | translate }}</h1>
          </div>
        </div>
      </div>
    </div>
  </section>
