<app-header></app-header>
<section class="banner-other">
  <div class="container">
    <div class="row">
      <div class="col s12 white-text">
        <h5 class="title-about">{{ "CONTACT-US.title-section-1" | translate }}</h5>
      </div>
    </div>
  </div>
</section>

<main>
  <section class="">
    <div class="container">
      <div class="row">
        <div class="col s12 m12 l12" data-aos="fade-up" data-aos-delay="100">
          <div class="center-align">
            <h4 class="espaco-p-1 negrito grey-text text-darken-3">{{ "CONTACT-US.title-section-2" | translate }}</h4>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col s12 m10 l10 offset-l1 offset-m1" data-aos="fade-up" data-aos-delay="200">
          <div class="row center espaco-m-2">
            <div class="col s12 m4 l4">
              <i class="fa fa-envelope  text-color-1 fa-5x cor1"></i>
                <h6 class="grey-text">informacao-geral@espaes.com</h6>
            </div>

            <div class="col s12 m4 l4" data-aos="fade-up" data-aos-delay="300">
                <i class="fa fa-paper-plane  text-color-1 fa-5x cor1"></i>
                <h6 class="grey-text">Av. 21 de Janeiro, Condomínio Retail Park, Edificio 4A - 2º Andar</h6>
            </div>

            <div class="col s12 m4 l4" data-aos="fade-up" data-aos-delay="400">
              <i class="fa fa-mobile-alt text-color-1 fa-5x cor1"></i>
                <h6 class="grey-text">(+244) 923 804 655</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="grey lighten-5 espaco-p-baixo-1">
    <div class="container">
      <div class="row">
        <div class="col s12 m12 l12" data-aos="fade-up" data-aos-delay="100">
          <div class="center-align">
            <h5 class="grey-text">{{ "CONTACT-US.paragraph-1" | translate }}</h5>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col s12 m8 l8 offset-l2" data-aos="fade-up" data-aos-delay="300">
          <form method="post" class="white espaco-p-2 z-depth-3" role="form" enctype="multipart/form-data">
            <input type="hidden" name="csrfmiddlewaretoken" value="aM0cbVCodlWmn4281hiSZWq5b8pzqavnyJJbqaAUC5f1YeVUC3fG3iBYHt5mBmUq">
            <div class="row">
              <div class="col s12 m12 l12">
                <input type="text" name="nome" class="browser-default estilo-input" maxlength="50" required id="id_nome">
                <label for="">{{ "CONTACT-US.form-label-name" | translate }} </label>
              </div>
            </div>
            <div class="row">
              <div class="col s12 m12 l12">
                <input type="text" name="telefone" class="browser-default estilo-input" maxlength="20" required id="id_telefone">
                <label for="">{{ "CONTACT-US.form-label-phone" | translate }} </label>
              </div>
              <div class="col s12 m12 l12">
                <input type="email" name="email" class="browser-default estilo-input" maxlength="254" required id="id_email">
                <label for="">{{ "CONTACT-US.form-label-email" | translate }} </label>

              </div>
            </div>
            <div class="row">
              <div class="col s12 m12 l12">
                <select name="assunto" class="browser-default estilo-input" required id="id_assunto">
    <option value="" selected>---------</option>
    <option value="Solicitar serviço">Solicitar serviço</option>
    <option value="Solicitar apresentação">Solicitar apresentação</option>
    <option value="Sugestão">Sugestão</option>
    <option value="Elogio">Elogio</option>
    <option value="Crítica">Crítica</option>
    <option value="Outro">Outro</option>
  </select>
                <label for="">{{ "CONTACT-US.form-label-subject" | translate }} </label>

              </div>
            </div>
            <div class="row">
              <div class="col s12 m12 l12">
                <textarea name="mensagem" cols="40" rows="10" class="browser-default estilo-input" required id="id_mensagem"></textarea>
                <label for="">{{ "CONTACT-US.form-label-message" | translate }} </label>
              </div>
            </div>

            <!-- <div class="row center">
              <div class="col s12 m12 l12 center center-align">
                <span class="negrito texto-1">{{ "CONTACT-US.paragraph-form-1" | translate }} </span>
                <input type="file" name="anexoFile" class="browser-default estilo-input" id="id_anexoFile">
              </div>
            </div> -->
            <div class="row">
              <div class="col s12 m12 l12 ">
                <div class="center center-block">
                  <button type="submit" class="btn btn-large bg-1"><span class="fa fa-envelope"></span> {{ "CONTACT-US.form-button-send-text" | translate }} </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</main>


<app-footer></app-footer>
