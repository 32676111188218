import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  title = "Fale connosco"

  constructor(private titleService:Title) {
    this.titleService.setTitle(`ESPAES - ${this.title}`);
  }

  ngOnInit(): void {
  }

}
