<div class="navbar-fixed ">
  <nav class="z-depth-3">
    <div class="container nav-wrapper">
      <a href="#" data-target="mobile-demo" class="sidenav-trigger"><i class="fa fa-bars"></i></a>
      <ul class="left hide-on-med-and-down">
        <li><a routerLink="/inicio" routerLinkActive="active">{{ "MENU.index" | translate }}</a></li>
        <li><a routerLink="/sobre-nos" routerLinkActive="active">{{ "MENU.about-us" | translate }}</a></li>
        <li><a routerLink="/oferta" routerLinkActive="active">{{ "MENU.offer" | translate }}</a></li>
        <li><a  routerLink="/industria" routerLinkActive="active">{{ "MENU.industry" | translate }}</a></li>
        <li><a  routerLink="/carreira" routerLinkActive="active">{{ "MENU.career" | translate }}</a></li>
        <li><a  routerLink="/fale-connosco" routerLinkActive="active">{{ "MENU.contact-us" | translate }}</a></li>
        <li><a (click)="translate.use('pt')" class="ms-5 pe-1">
          PT | </a></li>
        <li><a (click)="translate.use('en')" class="p-0 m-0">
          EN</a></li>
      </ul>
      <a routerLink="/" class="brand-logo right"><img src="assets/imgs/logo-espaes.png" style="width: 130px; height: auto; margin-top: 20px;" alt=""></a>
    </div>
  </nav>
</div>

<!-- <div style="position: relative;">
  <a id="fixedbutton" (click)="translate.use('pt')" class="btn blue darken-4">PT</a>
  <a id="fixedbutton" (click)="translate.use('en')" class="btn blue darken-4">EN</a>
</div> -->

<ul class="sidenav" id="mobile-demo">
  <li><a href="/inicio" routerLinkActive="active">{{ "MENU.index" | translate }}</a></li>
  <li><a href="/sobre-nos" routerLinkActive="active">{{ "MENU.about-us" | translate }}</a></li>
  <li><a href="/oferta" routerLinkActive="active">{{ "MENU.offer" | translate }}</a></li>
  <li><a href="/industria" routerLinkActive="active">{{ "MENU.industry" | translate }}</a></li>
  <li><a href="/carreira" routerLinkActive="active">{{ "MENU.career" | translate }}</a></li>
  <li><a href="/fale-connosco" routerLinkActive="active">{{ "MENU.contact-us" | translate }}</a></li>
  <div class="divider"></div>
  <li><a (click)="translate.use('pt')" class="">
    <img src="assets/imgs/flags/PT.jpg" style="width: 20px !important" alt="">
    PT</a></li>
  <li><a (click)="translate.use('en')" class="">
    <img src="assets/imgs/flags/GBP.jpg" style="width: 20px !important" alt="">
    EN</a></li>
</ul>
