import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(public translate: TranslateService) {
    translate.addLangs(['en','pt']);

  }

  ngOnInit(): void {
  }

  setDefaultLang(value){
    this.translate.setDefaultLang(value);
    this.translate.use(value);
  }

}
