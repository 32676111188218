<app-header></app-header>
<section class="banner-other">
  <div class="container">
    <div class="row">
      <div class="col s12 white-text">
        <h4 class="title-about">{{ "OFFER.title-section-1" | translate }}</h4>
        <!-- <h6 class="breadcrumbs"><a routerLink="/" class="grey-text">Início</a> / <span>{{title}}</span> </h6> -->
      </div>
    </div>
  </div>
</section>

<main>
  <section class="espaco-p-top-1 espaco-p-baixo-1">
    <div class="container">
      <div class="row">
        <div class="col s12 m12 l2">
          <img src="assets/imgs/logo-espaes-3.jfif" class="mt-4 responsive-img" alt="">
        </div>
        <div class="col s12 m12 l10">
          <h5 class="top-title text-color-1">ESPAES</h5>
          <h3 class="title-page text-color-1">{{ "OFFER.title-section-1" | translate }} </h3>
          <p>{{ "OFFER.paragraph-1" | translate }}
            </p>
        </div>
      </div>
    </div>
  </section>


  <section class="fundo-2 espaco-p-top-1 espaco-p-baixo-1">
    <div class="container">
      <div class="row">
        <div class="col s12 m12 l2">

        </div>

        <div class="col s12 m12 l10">
          <div class="row">
            <div class="col s12">
              <h5 class="top-title text-color-1">ESPAES</h5>
              <h3 class="title-page text-color-1">{{ "OFFER.title-section-2" | translate }} </h3>
            </div>
          </div>

          <div class="row">
            <div class="col s12">
              <h5 class="top-title text-color-1 negrito texto-1">{{ "OFFER.ti-title" | translate }}</h5>
            </div>
            <div class="col s12 m6 l3">
              <div class="card-panel ">
                <span class="white-text">{{ "OFFER.ti-area-1" | translate }}
                </span>
              </div>
            </div>


            <div class="col s12 m6 l3">
              <div class="card-panel ">
                <span class="white-text">{{ "OFFER.ti-area-2" | translate }}
                </span>
              </div>
            </div>


            <div class="col s12 m6 l3">
              <div class="card-panel ">
                <span class="white-text">{{ "OFFER.ti-area-3" | translate }}
                </span>
              </div>
            </div>


            <div class="col s12 m6 l3">
              <div class="card-panel ">
                <span class="white-text">{{ "OFFER.ti-area-4" | translate }}
                </span>
              </div>
            </div>


            <div class="col s12 m6 l3">
              <div class="card-panel ">
                <span class="white-text">{{ "OFFER.ti-area-5" | translate }}
                </span>
              </div>
            </div>

            <div class="col s12 m6 l3">
              <div class="card-panel ">
                <span class="white-text">{{ "OFFER.ti-area-6" | translate }}
                </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col s12">
              <h5 class="top-title text-color-1 negrito texto-1">{{ "OFFER.legal-Services-title" | translate }}</h5>
            </div>
            <div class="col s12 m6 l3">
              <div class="card-panel ">
                <span class="white-text">{{ "OFFER.legal-Services-area-1" | translate }}
                </span>
              </div>
            </div>

            <div class="col s12 m6 l3">
              <div class="card-panel ">
                <span class="white-text">{{ "OFFER.legal-Services-area-2" | translate }}
                </span>
              </div>
            </div>

            <div class="col s12 m6 l3">
              <div class="card-panel ">
                <span class="white-text">{{ "OFFER.legal-Services-area-3" | translate }}
                </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col s12">
              <h5 class="top-title text-color-1 negrito texto-1">{{ "OFFER.electronic-banking-title" | translate }}  </h5>
            </div>
            <div class="col s12 m6 l3">
              <div class="card-panel ">
                <span class="white-text">{{ "OFFER.electronic-banking-area-1" | translate }}
                </span>
              </div>
            </div>

            <div class="col s12 m6 l3">
              <div class="card-panel ">
                <span class="white-text">{{ "OFFER.electronic-banking-area-2" | translate }}
                </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col s12">
              <h5 class="top-title text-color-1 negrito texto-1">{{ "OFFER.heritage-security-title" | translate }}</h5>
            </div>
            <div class="col s12 m6 l3">
              <div class="card-panel ">
                <span class="white-text">{{ "OFFER.heritage-security-area-1" | translate }}
                </span>
              </div>
            </div>

            <div class="col s12 m6 l3">
              <div class="card-panel ">
                <span class="white-text">{{ "OFFER.heritage-security-area-2" | translate }}
                </span>
              </div>
            </div>

            <div class="col s12 m6 l3">
              <div class="card-panel ">
                <span class="white-text">{{ "OFFER.heritage-security-area-3" | translate }}
                </span>
              </div>
            </div>

            <div class="col s12 m6 l3">
              <div class="card-panel ">
                <span class="white-text">{{ "OFFER.heritage-security-area-4" | translate }}
                </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col s12">
              <h5 class="top-title text-color-1 negrito texto-1">{{ "OFFER.marketing-title" | translate }}</h5>
            </div>
            <div class="col s12 m6 l3">
              <div class="card-panel ">
                <span class="white-text">{{ "OFFER.marketing-area-1" | translate }}
                </span>
              </div>
            </div>

            <div class="col s12 m6 l3">
              <div class="card-panel ">
                <span class="white-text">{{ "OFFER.marketing-area-2" | translate }}
                </span>
              </div>
            </div>

            <div class="col s12 m6 l3">
              <div class="card-panel ">
                <span class="white-text">{{ "OFFER.marketing-area-3" | translate }}
                </span>
              </div>
            </div>

            <div class="col s12 m6 l3">
              <div class="card-panel ">
                <span class="white-text">{{ "OFFER.marketing-area-4" | translate }}
                </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col s12">
              <h5 class="top-title text-color-1 negrito texto-1">{{ "OFFER.human-capital-title" | translate }}  </h5>
            </div>
            <div class="col s12 m6 l3">
              <div class="card-panel ">
                <span class="white-text">{{ "OFFER.human-capital-area-1" | translate }}
                </span>
              </div>
            </div>
            <div class="col s12 m6 l3">
              <div class="card-panel ">
                <span class="white-text">{{ "OFFER.human-capital-area-2" | translate }}
                </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col s12">
              <h5 class="top-title text-color-1 negrito texto-1">{{ "OFFER.communication-media-title" | translate }} </h5>
            </div>
            <div class="col s12 m6 l3">
              <div class="card-panel ">
                <span class="white-text">{{ "OFFER.communication-media-area-1" | translate }}
                </span>
              </div>
            </div>
             <div class="col s12 m6 l3">
              <div class="card-panel ">
                <span class="white-text">{{ "OFFER.communication-media-area-2" | translate }}
                </span>
              </div>
            </div>
             <div class="col s12 m6 l3">
              <div class="card-panel ">
                <span class="white-text">{{ "OFFER.communication-media-area-3" | translate }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>

  <section class="bg-1 espaco-p-top-1 espaco-p-baixo-1">
    <div class="container">
      <div class="row">
        <div class="col s12 m12 l2">

        </div>
        <div class="col s12 m12 l10">
          <div class="row">
            <div class="col s12">
              <h5 class="top-title white-text">ESPAES</h5>
              <h3 class="title-page white-text">{{ "OFFER.title-section-3" | translate }}</h3>
              <ul class="bg-1 collection white-text sem-borda">
                <li class="bg-1 collection-item">{{ "OFFER.list-item-1" | translate }}</li>
                <li class="bg-1 collection-item">{{ "OFFER.list-item-2" | translate }}</li>
                <li class="bg-1 collection-item">{{ "OFFER.list-item-3" | translate }}</li>
                <li class="bg-1 collection-item">{{ "OFFER.list-item-4" | translate }}</li>
                <li class="bg-1 collection-item">{{ "OFFER.list-item-5" | translate }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>

<app-footer></app-footer>
