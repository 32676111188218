import { Component, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-industry',
  templateUrl: './industry.component.html',
  styleUrls: ['./industry.component.css']
})
export class IndustryComponent implements OnInit {
  title = "Indústria"

  constructor(private titleService:Title) {
    this.titleService.setTitle(`ESPAES - ${this.title}`);
  }

  ngOnInit(): void {
  }

}
