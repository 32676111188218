import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.css']
})
export class CareerComponent implements OnInit {
  title :any = "Carreiras"
  constructor(private titleService:Title) {
    this.titleService.setTitle(`ESPAES - ${this.title}`);
  }

  ngOnInit(): void {
  }

}
