import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutUsComponent } from './views/about-us/about-us.component';
import { CareerComponent } from './views/career/career.component';
import { ContactUsComponent } from './views/contact-us/contact-us.component';
import { HomeComponent } from './views/home/home.component';
import { IndustryComponent } from './views/industry/industry.component';
import { OfferComponent } from './views/offer/offer.component';


const routes: Routes = [
  {path: 'inicio', component:HomeComponent},
  {path:'', redirectTo: 'inicio', pathMatch: 'full'},
  {path: 'sobre-nos', component:AboutUsComponent},
  {path: 'carreira', component:CareerComponent},
  {path: 'oferta', component:OfferComponent},
  {path: 'industria', component:IndustryComponent},
  {path: 'fale-connosco', component:ContactUsComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
